<template>
  <k-ajax-multi-select
    :value="value"
    @input="$emit('input', $event)"
    :multiple="multiple"
    labelBy="Text"
    :validator="validator"
    :getOptions="GetUserek"
    group-values="Children"
    :group-select="false"
    group-label="Group"
    :clear-on-select="true"
    :close-on-select="true"
    placeholder="Kezdjen el gépelni..."
    tagPlaceholder="Kezdjen el gépelni..."
  >
    <template slot="singleLabel" slot-scope="props">
      <div :class="{ kulso: props.option.KulsoFl }">{{ props.option.Text }}</div>
    </template>
    <template slot="option" slot-scope="props">
      <div v-html="props.option.$groupLabel || props.option.Text"></div>
    </template>
    <template slot="noOptions">
      Nincs találat
    </template>
  </k-ajax-multi-select>
</template>

<script>
import { mapGetters } from 'vuex';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { userService } from '../../services/UserService';

export default {
  name: 'user-valaszto',
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {
    async GetUserek(keresoszoveg) {
      let options = [];

      if (keresoszoveg.length > 0) {
        try {
          let userek = await userService.GetUsersByKeresoSzoveg({
            keresoszoveg,
          });

          // options = userek.map(m => {
          //   return { Id: m.Id, Text: m.Nev + ' (' + m.Email + ')' };
          // });
          options = userek.map(m => {
            return {
              Id: m.Id,
              Group: m.Text,
              Children: m.Children.map(n => {
                return {
                  Id: n.Id,
                  Text: n.Text,
                  KulsoFl: n.KulsoFl
                };
              }),
            };
          });
        } catch (error) {
          NotificationFunctions.AjaxError({ error });
        }
      }

      options.unshift({
        Id: 0,
        Group: 'Mindenki',
        Children: [{
          Id: 1,
          Text: 'Mindenki - összes felhasználó megkapja',
          KulsoFl: 0
        }]
      });

      return options;
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: true,
    },
    validator: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>

<style scoped>

  .kulso {
    color: #ff0000;
  }

</style>